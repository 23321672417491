import { getImageUrlWithSportType, getScoreFootball } from 'hooks';
import React, { useState, useEffect, useRef } from 'react';
import { getTimeDetailLive } from 'hooks';

function ItemShowScores({ currentIndex, match, index, sportType }) {
  const isActive = currentIndex === index;
  return match && match?.matchId ? (
    <div
      className="as-scores-wrapper"
      style={{
        opacity: isActive ? 1 : 0,
        transition: 'opacity 0.5s ease-in-out',
        position: 'absolute',
        width: '100%',
        left: 0,
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '44px',
      }}
    >
      <div className="as-wrapper-item">
        <div className="as-timeline-wrapper">
          <img
            className="as-timeline-img"
            src={match.isScoreCancel ? '/goal-cancel.png' : '/icon-time.png'}
          />
          <div className="as-timeline-minutes">
            {match.timeScored && <span>{`${match.timeScored}'`}</span>}
          </div>
        </div>
        <div className="as-wrapper-player as-home">
          <div className="as-item-info">
            <span>{match.homeTeam.name}</span>
          </div>
          <div className="as-item-img">
            <img
              className="as-img-player"
              src={getImageUrlWithSportType(sportType, 'team', match.homeTeam.id)}
              alt={match.homeTeam}
            />
          </div>
        </div>
      </div>
      <div className="as-score-wrapper">
        <span className={`${match.isHomeGoaled ? 'as-goal' : ''}`}>
          {getScoreFootball(match.homeScore)}
        </span>
        <span>:</span>
        <span className={`${match.isHomeGoaled ? '' : 'as-goal'}`}>
          {getScoreFootball(match.awayScore)}
        </span>
      </div>
      <div className="as-wrapper-item">
        <div className="as-wrapper-player as-away">
          <div className="as-item-info">
            <span>{match.awayTeam.name}</span>
          </div>
          <div className="as-item-img">
            <img
              className="as-img-player"
              src={getImageUrlWithSportType(sportType, 'team', match.awayTeam.id)}
              alt={match.awayTeam}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default ItemShowScores;
