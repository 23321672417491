import { colors as colorsCon } from 'constant/colors';
let colors = colorsCon;
if (typeof window.colorsCommon !== 'undefined') {
  colors = [...window.colorsCommon];
}

const limitCountLevel = 500;

// totalMessages: Tổng số tin nhắn mà người dùng đã gửi.
// limitCountLevel (mặc định là 500): Số lượng tin nhắn cần để tăng lên mỗi cấp độ.
// initialLevelThreshold (mặc định là 20): Số cấp độ đầu tiên được tính theo một cơ chế riêng.
// currentLevel (mặc định là 1): Level hiện tại của user
const getLevel = (totalMessages, currentLevel = 1, limitCountLevel = 500, initialLevelThreshold = 20) => {
  try {
    if (totalMessages < 1) return 1; // Level mặc định là 1 khi chưa có tin nhắn nào được gửi
    let level = 1; // Bắt đầu từ level 1
    let messagesThreshold = limitCountLevel * initialLevelThreshold; // Tính ngưỡng tin nhắn cho 20 levels đầu tiên

    if (totalMessages < messagesThreshold) {
      level += Math.floor(totalMessages / limitCountLevel);
    } else {
      totalMessages -= messagesThreshold; // Bỏ qua số tin nhắn đã được dùng để đạt level 20
      level += initialLevelThreshold - 1;

      // Tính cho các level từ 21 đến 99 (mỗi level thêm 2000 tin nhắn)
      const nextThreshold = 79 * (limitCountLevel * 4); // 79 levels tiếp theo (21-99)
      if (totalMessages < nextThreshold) {
        level += Math.floor(totalMessages / (limitCountLevel * 4));
      } else {
        totalMessages -= nextThreshold;
        level += 79;

        // Tính cho level từ 100 trở đi (mỗi level thêm 500 * 10 = 5000 tin nhắn)
        level += Math.floor(totalMessages / (limitCountLevel * 10));
      }
    }

    // Nếu level tính toán vượt quá cấp hiện tại, giữ nguyên level cũ cho user
    // tránh làm giảm level hiện tại của user
    return Math.max(currentLevel, level);
  } catch (error) {
    return 1;
  }
};

export const useUser = (data = {}) => {
  const isAdmin = data?.role === 'ADMIN';
  const isMod = data?.role === 'MOD';
  const isManager = isAdmin || isMod;
  const deactive = data?.deactive;
  const messageCount = data?.messageCount;
  const currentLevel = data?.level | 1;
  const level = getLevel(messageCount, currentLevel) || 1;
  const messageCountLevel =
    messageCount < limitCountLevel ? messageCount : messageCount - (level - 1) * limitCountLevel;

  const sendAllow = () => {
    if (isManager || !deactive) return true;
    try {
      const now = Date.now();
      return now > deactive;
    } catch (error) {
      return true;
    }
  };

  return {
    isAdmin,
    isMod,
    isManager,
    sendAllow,
    deactive,
    messageCount,
    messageCountLevel,
    color: colors[level - 1],
    level: level,
  };
};
