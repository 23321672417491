import axios from 'axios';

const BASE_URL = 'https://data.api-football.xyz';

export const getMatchLive = async (
  apiLiveUrl = '',
  sportType = 'football',
  siteName = 'xoilacz',
) => {
  return await axios.get(
    `${apiLiveUrl ? apiLiveUrl : BASE_URL}/v1/${sportType}/${siteName}/match/live`,
  );
};

export const getMatchFootballLive = async (apiLiveUrl = '', siteName = 'xoilacz') => {
  return await axios.get(
    `${apiLiveUrl ? apiLiveUrl : BASE_URL}/v1/football/${siteName}/match/live`,
  );
};

export const getMatchBasketballLive = async (apiLiveUrl = '', siteName = 'xoilacz') => {
  return await axios.get(
    `${apiLiveUrl ? apiLiveUrl : BASE_URL}/v1/basketball/${siteName}/match/live`,
  );
};
