import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import ItemShowScores from './ItemShowScores';

function ShowScores({ sportType, matchScores }) {
  // Lưu trữ chỉ số của mục hiện tại
  const [currentIndex, setCurrentIndex] = useState(0);

  // Swipeable hooks with event handlers
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      // Swipe left: Move to next item if not the last item
      setCurrentIndex(prevIndex =>
        prevIndex < matchScores.length - 1 ? prevIndex + 1 : prevIndex,
      );
    },
    onSwipedRight: () => {
      // Swipe right: Move to previous item if not the first item
      setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    },
  });

  useEffect(() => {
    if (matchScores.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex(prevIndex => (prevIndex < matchScores.length - 1 ? prevIndex + 1 : 0));
      }, 10000);
      //10000; //10s
      return () => clearInterval(interval);
    }
  }, [matchScores.length]);

  const handleNext = () => {
    setCurrentIndex(prevIndex => (prevIndex < matchScores.length - 1 ? prevIndex + 1 : 0));
  };

  const handlePrevious = () => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : matchScores.length - 1));
  };

  return matchScores && matchScores.length > 0 ? (
    <div
      {...handlers}
      className="d-lg-flex mt-0 as-scores-main-wrapper"
      style={{
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {matchScores && matchScores.length > 1 && (
        <>
          <div className="as-handle-left" onClick={handlePrevious}>
            <img className="as-icon-handle" src="/icon-left.svg" />
          </div>
          <div className="as-handle-right" onClick={handleNext}>
            <img className="as-icon-handle" src="/icon-right.svg" />
          </div>
        </>
      )}
      <div className="as-scores-bg-wrapper">
        {matchScores &&
          matchScores.map((item, index) => (
            <ItemShowScores
              key={index}
              match={item}
              currentIndex={currentIndex}
              index={index}
              sportType={sportType}
            />
          ))}
      </div>
    </div>
  ) : null;
}

export default ShowScores;
