import moment from 'moment';
export const allow_sport_type_show = ['football'];

export const sport_data = {
  football: {
    status_deny: [8, 12, 13],
    status_playing: [2, 3, 4, 5, 6, 7],
    mqtt_topic: 'fb-live-score',
    status: [
      'Bất thường',
      'Chưa bắt đầu',
      'Hiệp 1',
      'HT',
      'Hiệp 2',
      'Hiệp phụ',
      'Hiệp phụ',
      'Pen',
      'Kết thúc',
      'Trì hoãn',
      'Gián đoạn',
      'Cắt một nửa',
      'Hủy bỏ',
      'Chưa xác định',
    ],
  },
  basketball: {
    status_deny: [10, 11, 12, 13, 14, 15],
    status_playing: [2, 3, 4, 5, 6, 7, 8, 9],
    mqtt_topic: 'basket-live',
    status: [
      'Bất thường',
      'Chưa bắt đầu',
      'Hiệp 1',
      'Hết hiệp 1',
      'Hiệp 2',
      'Hết hiệp 2',
      'Hiệp 3',
      'Hết hiệp 3',
      'Hiệp 4',
      'Hiệp phụ',
      'Kết thúc',
      'Gián đoạn',
      'Hủy bỏ',
      'Phần mở rộng',
      'Cắt một nửa',
      'Chưa xác định',
    ],
  },
  tennis: {
    status_deny: [15, 16, 18, 99, 100],
    status_playing: [3, 51, 52, 53, 54, 55],
    mqtt_topic: 'tennis-live',
    status: {
      0: 'Bất thường',
      1: 'Chưa bắt đầu',
      3: 'Đang diễn ra',
      14: 'Hoãn lại',
      15: 'Bị trễ',
      16: 'Đã hủy',
      17: 'Gián đoạn',
      18: 'Tạm dừng',
      19: 'Cắt đôi',
      20: 'Thắng ván',
      21: 'Rút lui',
      22: 'Thắng ván 1',
      23: 'Thắng ván 2',
      24: 'Rút lui 1',
      25: 'Rút lui 2',
      26: 'Mất quyền 1',
      27: 'Mất quyền 2',
      51: 'SET 1',
      52: 'SET 2',
      53: 'SET 3',
      54: 'SET 4',
      55: 'SET 5',
      99: 'Chưa xác định',
      100: 'Kết thúc',
    },
  },
};

export const parseScores = (scores, mode = 'home') => {
  let score = scores?.[0] || 0;
  if (scores?.[5] && scores?.[5] > 0) {
    score = scores?.[5];
  }

  return score;
};

export const get_match_by_id = (data, match_id) => {
  if (!data) {
    return false;
  }
  var result = data.filter(function (match) {
    return match.id == match_id;
  });
  if (result) {
    return result[0];
  }
  return false;
};

const getNowTime = () => {
  var d = new Date();
  return new Date(d.getTime() + d.getTimezoneOffset() * 60000 + 7 * 60 * 60000);
};

export const getCurrentDateTimestamp = () => {
  const currentDateTimestamp = Date.now() / 1000;

  return currentDateTimestamp;
};

const timestampToMinutesSeconds = timestamp => {
  const minutes = Math.floor(timestamp / 60);
  const seconds = timestamp % 60;
  return { minutes, seconds };
};

const getStatusTimeTexts = (sportName, minutesApp, statusId) => {
  const statusTimeTexts = {
    football: {
      2: minutesApp > 45 ? `45+${Math.floor(minutesApp - 45)}'` : `${minutesApp}'`,
      4: minutesApp + 45 > 90 ? `90+${Math.floor(minutesApp + 45 - 90)}'` : `${45 + minutesApp}'`,
    },
    basketball: {
      2: minutesApp > 12 ? "12'" : minutesApp + "'",
      4: minutesApp > 12 ? "12'" : minutesApp + "'",
      6: minutesApp > 12 ? "12'" : minutesApp + "'",
      8: minutesApp > 12 ? "12'" : minutesApp + "'",
    },
  };

  return statusTimeTexts[sportName][statusId] ? `: ${statusTimeTexts[sportName][statusId]}` : '';
};

export const getTimeDetailLive = (
  sportName,
  statusId,
  startTimestamp = null,
  current_time = null,
) => {
  const stautsText = sport_data[sportName].status;
  const statusTxt = stautsText[statusId] || '';
  const statusStop = sport_data[sportName].status_deny;

  if (statusStop.includes(statusId) || !startTimestamp) {
    return {
      text: statusTxt,
      minutes: statusTxt,
      seconds: 0,
      timeText: statusTxt,
    };
  }

  let currentDate = getCurrentDateTimestamp();
  if (current_time) {
    currentDate = current_time;
  }
  let remaining = currentDate - startTimestamp;
  if (sportName === 'basketball') {
    remaining = startTimestamp;
  }
  const { minutes, seconds } = timestampToMinutesSeconds(remaining);
  const minutesApp = Math.round(seconds) > 29 ? minutes + 1 : minutes;
  const timeText = getStatusTimeTexts(sportName, minutes, statusId);
  let _timeText = timeText.replace(': ', '');
  if ([5, 6].includes(statusId)) {
    _timeText = statusTxt;
  }
  return {
    text: `${statusTxt}${timeText}`,
    minutes: minutes,
    seconds: seconds,
    timeText: _timeText,
  };
};

export const getMatchData = (sportName, match) => {
  if (sportName === 'football') {
    const statusCode = match?.score?.[1] || 0;
    const homeScores = match?.score?.[2] || 0;
    const awayScores = match.score[3];
    let homeScore = homeScores[0];
    let awayScore = awayScores[0];
    if (homeScores[5] > 0) {
      homeScore = homeScores[5];
    }
    if (awayScores[5] > 0) {
      awayScore = awayScores[5];
    }
    const halfStartTime = match.score[4];
    const homeHalfScore = homeScores[1];
    const homeCorner = homeScores[4];
    const awayYellowCards = awayScores[3];
    const homeYellowCards = homeScores[3];
    const awayHalfScore = awayScores[1];
    const awayCorner = awayScores[4];

    return {
      statusCode,
      homeScores,
      homeScore,
      homeHalfScore,
      homeCorner,
      homeYellowCards,
      awayScores,
      awayScore,
      awayHalfScore,
      awayCorner,
      awayYellowCards,
      halfStartTime,
      math_time_player: getTimeDetailLive(sportName, statusCode, halfStartTime),
    };
  } else if (sportName === 'basketball') {
    const statusCode = match.score[1];
    const homeScores = match.score[3];
    const awayScores = match.score[4];
    const homeScore = homeScores.reduce((partialSum, a) => partialSum + a, 0);
    const awayScore = awayScores.reduce((partialSum, a) => partialSum + a, 0);
    const halfStartTime = match.timer[3];

    return {
      statusCode,
      homeScores,
      homeScore,
      awayScores,
      awayScore,
      halfStartTime,
    };
  } else if (sportName === 'tennis') {
    const statusCode = match.score[1];
    let homeScore = 0;
    let awayScore = 0;
    if (match.score[3].ft) {
      homeScore = match.score[3].ft[0];
      awayScore = match.score[3].ft[1];
    }

    return {
      statusCode,
      homeScore,
      awayScore,
      serve: match.score[2],
      score: match.score[3],
    };
  }
};

export const getScoreFootball = (scores, statusId = 0) => {
  let score = scores?.regular || 0;

  if (statusId == 5) {
    score = scores?.ot || 0;
  }

  if (statusId == 7) {
    score = scores?.penalty || 0;
  }

  return score;
};

export const getCurrentDate = () => {
  return moment().format('DD/MM/yyyy hh:mm:ss');
};
