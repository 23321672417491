import axios from 'axios';

const BASE_URL = {
  football: 'https://v1.api-football.xyz',
  basketball: 'https://bkb.api-football.xyz',
  tennis: 'https://tn.api-football.xyz',
  badminton: 'https://badminton.api-football.xyz',
  volleyball: 'https://volleyball.api-football.xyz',
};

const getApiUrl = (sportType, path) => {
  return `${BASE_URL[sportType]}/${path}`;
};

export const matchDetail = async (matchId, sportType = 'football') => {
  const url = getApiUrl(sportType, `${sportType}/match/${matchId}`);

  return await axios.get(url);
};

export const matchDetailLive = async (sportType = 'football') => {
  const url = getApiUrl(sportType, `${sportType}/match/detail_live`);

  return await axios.get(url);
};

export const getImageUrlWithSportType = (sportType = 'football', type, id = '') => {
  if (id) {
    return `https://img.vbfast.xyz/${sportType}/${type}/${id}/image/small`;
  }

  return '';
};
